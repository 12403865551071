import React from 'react';
import TextPage, { TextPageSection } from '../templates/text-page';

const About = () => (
  <TextPage title="About">
    <TextPageSection title="The next evolution of the investigation: DesignKind 2022, digital showcase">
      <p>
        DesignKind is a series of collaborations between emerging designers from
        South Asia, Sub-Saharan Africa and the United Kingdom curated by
        {' '}
        <a href="https://design.britishcouncil.org/">British Council</a>
        ,
        {' '}
        <a href="https://www.instagram.com/dothegreenthing/">Do the Green Thing</a>
        {' '}
        and
        {' '}
        <a href="https://www.instagram.com/pentagramdesign/?hl=en">Pentagram</a>
        {' '}
        exploring the relationship between racial and climate justice.
      </p>
      <p>
        The DesignKind programme paired eight emerging designers and design
        collectives (11 designers in total) from South Asia, Sub-Saharan Africa
        and the United Kingdom working across a range of design disciplines into
        four cross-cultural collaborations over a six-week period.
      </p>
      <p>
        Through a process of research and critical dialogue - and with the help
        of innovative digital collaboration tools - each pair of designers has
        created a piece of interdisciplinary work exploring the intersection of
        racial justice and climate justice.The designers explored mediums and
        themes including climate migration storytelling, comparative climate
        experiences in the oral tradition, the mapping of colonial and climate
        histories through materials, machine learning and creative coding.
      </p>
      <p>
        Google Arts and Culture will also feature the work along with further
        contributions from the designers documenting and describing their
        interdisciplinary, cross-cultural collaborative process. The showcase
        will also be celebrated throughout the year with physical exhibitions in
        London and with potential evolutions with British Council overseas
        partners in India, Ghana, Nigeria and more.
      </p>
    </TextPageSection>
    <TextPageSection title="The project partners">
      <p>
        DesignKind is a pilot that evolved out of British Council’s
        {' '}
        <a href="https://design.britishcouncil.org/blog/2019/sep/16/design-connections-10x10-2019/">10x10 emerging designer programme</a>
        {' '}
        and Do The Green Thing’s
        {' '}
        <a href="https://thecolouroftheclimatecrisis.art/">The Colour of the Climate Crisis</a>
        {' '}
        with the production and facilitation of Pentagram Design.
      </p>
      <p>
        The eight emerging designers and design collectives were selected from a
        shortlist by an international selection committee including architect
        {' '}
        <a href="https://anupamakundoo.com/">Anupama Kundoo</a>
        , fashion stylist and creative director
        {' '}
        <a href="https://www.instagram.com/sunnydolat/?hl=en">Sunny Dolat</a>
        , British Council design lead
        {' '}
        <a href="https://design.britishcouncil.org/about/team/">Parvinder Marwaha</a>
        {' '}
        and art director
        {' '}
        <a href="https://www.instagram.com/big_whoop_/?hl=en">Ailbhe Larkin</a>
        .
      </p>
    </TextPageSection>
    <TextPageSection title="Thank You">
      <p>
        We are grateful to our hosting partners
        {' '}
        <a href="https://artsandculture.google.com/">Google Arts and Culture</a>
        {' '}
        and
        {' '}
        <a href="https://goodpraxis.coop">Good Praxis</a>
        . We are also grateful to our supporters Disegno, Creative Boom,
        Earthrise, Azeema Mag, Eye on Design, What Design Can Do, Print Mag, Eye
        Mag.
      </p>
    </TextPageSection>
    <TextPageSection title="Our DesignKind project team">
      <ul className="list-no-deco">
        <li>Parvinder Marwaha, Programme Manager</li>
        <li>Naresh Ramchandani, Creative Director</li>
        <li>Shona Slemon, Producer and Project Manager</li>
        <li>Isla Wickham, Assistant Producer and Project Manager</li>
        <li>Violet Wilson, Art Director and Social Media Manager</li>
        <li>Ailbhe Larkin, Art Director</li>
        <li>Ashley Johnson, Writer</li>
        <li>Robyn Cusworth, Writer</li>
        <li>Gute Immelman, Project coordinator</li>
      </ul>
    </TextPageSection>
    <TextPageSection title="Partners">
      <h3>Curated by:</h3>
      <ul className="list-no-deco logo-list">
        <li>
          <a href="https://www.instagram.com/britisharts/?hl=en">
            <img src="/DK_Partner-logos/BritishCouncil.png" alt="British Council" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/dothegreenthing/?hl=en">
            <img src="/DK_Partner-logos/DoTheGreenThing.png" alt="Do The Green Thing" />
          </a>
        </li>
        <li>
          <a href="https://www.pentagram.com/">
            <img src="/DK_Partner-logos/Pentagram.png" alt="Pentagram Design" />
          </a>
        </li>
      </ul>
      <h3>Hosted by:</h3>
      <ul className="list-no-deco logo-list">
        <li>
          <a href="https://artsandculture.google.com/">
            <img src="/DK_Partner-logos/GoogleArtsAndCulture.png" alt="Google Arts and Culture" />
          </a>
        </li>
      </ul>
      <h3>Supported by:</h3>
      <ul className="list-no-deco logo-list">
        <li>
          <a href="https://disegnojournal.com/">
            <img src="/DK_Partner-logos/Disegno.png" alt="Disegno" />
          </a>
        </li>
        <li>
          <a href="https://www.creativeboom.com/">
            <img src="/DK_Partner-logos/creative-boom.png" alt="Creative Boom" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/earthrise.studio/?hl=en">
            <img src="/DK_Partner-logos/earthrise.png" alt="Earthrise" />
          </a>
        </li>
        <li>
          <a href="https://eyeondesign.aiga.org/">
            <img src="/DK_Partner-logos/AIGA.png" alt="Eye on Design" />
          </a>
        </li>
        <li>
          <a href="https://www.whatdesigncando.com/">
            <img src="/DK_Partner-logos/WhatDesignCanDo.png" alt="What Design Can Do" />
          </a>
        </li>
        <li>
          <a href="https://www.printmag.com/">
            <img src="/DK_Partner-logos/print.png" alt="Print Mag" />
          </a>
        </li>
        <li>
          <a href="https://www.eyemagazine.com/">
            <img src="/DK_Partner-logos/eye.png" alt="Eye Magazine" />
          </a>
        </li>
        <li>
          <a href="https://www.azeemamag.com/">
            <img src="/DK_Partner-logos/Azeema.png" alt="Azeema Mag" />
          </a>
        </li>
        <li>
          <a href="https://dublin.sciencegallery.com/">
            <img src="/DK_Partner-logos/science-gallery.png" alt="Science Gallery" />
          </a>
        </li>
      </ul>
    </TextPageSection>
    <hr className="about-hr" />
    <TextPageSection title="The beginning of our creative investigation: The Colour of the Climate Crisis, COP26 Glasgow and digital exhibition November 2021">
      <p>
        Art has always helped us to interpret the world and get closer to its
        truths.
      </p>
      <p>
        <i>The Colour of the Climate Crisis</i>
        {' '}
        grapples with one of those truths: that
        we are facing a crisis of climate and nature, and that the people most
        affected, most at risk and least responsible for it are people of
        colour.
      </p>
      <p>
        We know that racialised and minoritised communities are already on the
        frontlines of climate change. Their experiences, ideas and leadership
        must be central to the global response to it.
      </p>
      <p>
        As world leaders meet at COP26 to discuss the climate crisis, this
        exhibition offers them an opportunity: to be quiet, and listen. To be
        humble, and learn. To be brave, and commit to meaningful action.
      </p>
      <p>
        This is an opportunity for us all to acknowledge the truth that racial
        injustice is climate injustice, and to begin to change it.
      </p>

    </TextPageSection>
    <TextPageSection title="Do The Green Thing">
      <p>
        <i>The Colour of the Climate Crisis</i>
        {' '}
        is a project by
        {' '}
        <a href="https://dothegreenthing.com/" target="_blank" rel="noreferrer">
          Do The Green Thing
        </a>
        , an
        environmental social initiative that uses creativity to combat the
        climate crisis.
        {' '}
        <a
          href="https://www.instagram.com/dothegreenthing/"
          target="_blank"
          rel="noreferrer"
        >
          Come say hi.
        </a>
      </p>
    </TextPageSection>
    <TextPageSection title="Thank You">
      <p>
        We are grateful to our headline sponsor, Google, and to our supporters
        and partners including Greenpeace, University of the Arts London,
        D&amp;AD,
        {' '}
        <a href="https://goodpraxis.coop" className="no-deco-link">Good Praxis</a>
        , Pentagram Design, The Guardian, It’s Nice That,
        The Pipe Factory and The Necessary Space.
      </p>
    </TextPageSection>
  </TextPage>
);

export default About;
